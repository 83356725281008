import React from 'react';
import styled from 'styled-components';

const PanelContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 2rem;
`;

const MenuTitle = styled.h1`
	font-size: 2.4rem;
`;

const MenuContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

const PanelMenu = ({ title, children }) => (
	<PanelContainer>
		<MenuTitle>{title}</MenuTitle>
		<MenuContainer>{children}</MenuContainer>
	</PanelContainer>
);

export default PanelMenu;
