import React from 'react';
import styled from 'styled-components';

const AlertContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 5rem;
	margin-bottom: .75rem;
	padding: 1rem;
	background-color: white;
	border: 1px solid #D6E2E6;
	border-radius: 8px;
`;

const Icon = styled.img.attrs(props => ({
	src: props.src
}))`
	height: 3rem;
	width: 3rem;
	margin-right: 1rem;
`;

const Message = styled.h1`
	font-size: 1.5rem;
`;

const Alert = ({ iconSrc, message }) => {
	return (
		<AlertContainer>
			<Icon src={iconSrc}/>
			<Message>{message}</Message>
		</AlertContainer>
	);
}

export default Alert;
