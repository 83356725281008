import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Supplies from '../hooks/supplies';
import CameraPreview from './CameraPreview';
import PanelMenu from './PanelMenu';
import PanelButton from './PanelButton';

const CameraHeader = () => (
	<PanelMenu title="Supplies">
		<PanelButton>Add Shelf</PanelButton>
	</PanelMenu>
);

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	grid-gap: 0.75rem;
	border-radius: 8px;
`;

const CameraGrid = ({ suppliesPreviews, getSuppliesPreviews }) => {
	useEffect(() => {
		getSuppliesPreviews();
	}, [getSuppliesPreviews]);
	
	return (
		<Fragment>
			<CameraHeader />
			<Grid>
				{suppliesPreviews.map((preview, index) => {
					return (
						<CameraPreview 
							key={index}	
							id={preview.get('cameraID')}
							name={preview.get('name')}
							imageSrc={preview.get('url')}
							imageTimestamp={preview.get('timestamp')} />
					);
				})}	
			</Grid>
		</Fragment>
	);
}

const mapStateToProps = state => {
	return {
		suppliesPreviews: state.get('suppliesPreviews')
	}
}

export default connect(mapStateToProps, Supplies)(CameraGrid);
