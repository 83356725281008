import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	grid-template-areas: "t t"
						 "y b"
						 ". x";
	border-radius: 8px 8px 0 0;
	padding: 1rem;
	min-width: 60rem;
	overflow-x: scroll;
	background-color: white;
`;

const GraphContainer = props => {
	return (
    	<Container className={props.className}>
    		{props.children}
    	</Container>
    );
}

export default GraphContainer;
