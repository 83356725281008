import React, { useState } from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Search from '../hooks/search';
import SearchIcon from './SearchIcon';
import SearchFilter from './SearchFilter';
import SearchBar from './SearchBar';
import SearchResultsList from './SearchResultsList';

const parseResults = (results, filter) => {
    if (filter === 'all') {
    	const [ ...resultsLists ] = results.values();
    	return resultsLists.reduce((allResults, currList) => {
    		return allResults.concat(currList);
    	}, List());
    } else { return results.get(filter); }
}

const searchByFilter = (filter, query, handleSearchAll, handleSearchSupplies, handleSearchTasks) => {
	switch(filter) {
		case 'all': 
			handleSearchAll(query);
			break;
		case 'supplies':
			handleSearchSupplies(query);
			break;
		case 'tasks': 
			handleSearchTasks(query);
			break;
		default: 
			handleSearchAll(query);
	}
}

const SearchForm = styled.form`
	position: relative;
	display: grid;
	grid-template-rows: 3rem auto;
	grid-template-areas: "i f b"
						 ". r r";
	margin-left: 1rem;
`;

const AsyncSearch = props => {
	const [resultsVisible, setResultsVisible] = useState(false);
	const [query, setQuery] = useState('');
	const [filter, setFilter] = useState('all');
	const handleShowResults = () => setResultsVisible(true);
	const handleHideResults = () => setResultsVisible(false);
	const handleSubmit = e => e.preventDefault();
	const handleQuery = (e, filter) => {
		const query = e.target.value;
		setQuery(query);
		handleSearch(query, filter);
	};
	const handleFilter = (e, query) => {
		const filter = e.target.name;
		setFilter(filter); 
		handleSearch(query, filter);
	};
	const handleSearch = (query, filter) => {
		if (query.length > 0) { 
			handleShowResults(); 
			searchByFilter(filter, query, props.all, props.supplies, props.tasks);
		} else { handleHideResults(); }
	};
	
	return (
		<SearchForm onSubmit={handleSubmit}>
			<SearchIcon />
			<SearchFilter 	
				filter={filter} 
				query={query}
				handleFilter={handleFilter} />
			<SearchBar 		
				filter={filter}
				handleQuery={handleQuery} />
			{resultsVisible && <SearchResultsList 
				results={parseResults(props.searchResults, filter)}
				query={query}
				filter={filter}
				handleClose={handleHideResults} />}
		</SearchForm>
	);
}

const mapStateToProps = state => {
	return {
		'searchResults': state.get('searchResults')
	}
};

export default connect(
	mapStateToProps, 
	Search
)(AsyncSearch);
