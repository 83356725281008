import React from 'react';
import styled from 'styled-components';
import Alerts from './Alerts';
import Account from './Account';
import { logout } from '../hooks/auth';
import { connect } from 'react-redux';

const AlertsAccountContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const LogoutButton = styled.button`
	border: 1px solid white;
	margin-left: 0.75rem;
	padding: 0.45rem;
	border-radius: 5px;
	background-color: transparent;
	color: white;
	font-size: 1rem;
	font-weight: bold;
	:hover {
		background-color: white:
		color: #1E8DA6; 
	}
`;

const AlertsAndAccount = ({ logout }) => {
	return (
		<AlertsAccountContainer>
			<Account name="BG" />
			<Alerts />
			<LogoutButton onClick={logout}>Logout</LogoutButton>
		</AlertsAccountContainer>
	);
}

export default connect(null, { logout })(AlertsAndAccount);
