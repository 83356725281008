import React, { useEffect } from 'react';
import { Provider } from 'react-redux'
import store from './store';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Root from './Root';
import { loadUser, setAuthToken } from './hooks/auth';
import LivestreamContext from './contexts/livestream-context';
import * as serviceWorker from './serviceWorker';
import './index.css';

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const stream = {
	'status': 'connected',
	'hello': () => console.log('hello')
};

const RoutedRoot = ({ store }) => {
	useEffect(() => {
		if (localStorage.token) {
			store.dispatch(loadUser());
		}
	});

	return (
		<Provider store={store}>
			<LivestreamContext.Provider value={stream}>
				<BrowserRouter>
					<Root className="Root" />
				</BrowserRouter>
			</LivestreamContext.Provider>
		</Provider>
	);
}

ReactDOM.render(
	<RoutedRoot store={store} />,
	document.getElementById('root')
);

serviceWorker.unregister();
