import styled from 'styled-components';

const PanelButton = styled.button`
  min-height: 3rem;
  padding: 0 1rem;
  border: 1px solid #9ACBD6;
  border-radius: 8px;
  margin-left: .75rem;
  font-size: 1.5rem;
  color: #1E8DA6;
  background-color: white;
  transition: all .25s;
  overflow-wrap: no-wrap;
  &:hover {
    border: 1px solid #1E8DA6;
  } 
`;

export default PanelButton;
