import React, { Fragment, useEffect } from 'react';
import GraphListAnalytics from './GraphListAnalytics';
import PanelMenu from './PanelMenu';
import PanelButton from './PanelButton';

const AnalyticsHeader = () => (
  <PanelMenu title="Analytics"> 
    <PanelButton>Add Report</PanelButton>
  </PanelMenu>
);

const Analytics = props => {
  useEffect(() => {
    document.title = "Analytics";
  });
  
  return (
  	<Fragment>
  		<AnalyticsHeader />
      <GraphListAnalytics />
  	</Fragment>
  );
}

export default Analytics;
