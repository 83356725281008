import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as Actions from '../action-creators';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const ScannerTitle = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 2rem;
`; 

const Scanner = ({ createMobileRequest }) => {	
	return (
		<Fragment>
			<ScannerTitle>Scanner</ScannerTitle>
			<Camera
				isImageMirror = {false}
				idealFacingMode={FACING_MODES.ENVIRONMENT}
				imageType = {IMAGE_TYPES.JPG}
				onTakePhoto={encodedImage => createMobileRequest(encodedImage, Date.now())}
			/>
		</Fragment>
	);
}

export default connect(null, Actions)(Scanner);
