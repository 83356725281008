import uuid from 'uuid';

export function setTitle(title) {
  return {
    type: 'SET_TITLE', 
    title: title
  };
}

export function addTask(task) {
  return {
    type: 'ADD_TASK',
    task: task
  };
}

export function registerSuccess(token) {
  return {
    type: 'REGISTER_SUCCESS',
    token: token
  };
}

export function registerFail() {
  return {
    type: 'REGISTER_FAIL'
  };
}

export function loginSuccess(token) {
  return {
    type: 'LOGIN_SUCCESS',
    token: token
  };
}

export function loginFail() {
  return {
    type: 'LOGIN_FAIL'
  };
}

export function userLoaded(payload) {
  return {
    type: 'USER_LOADED',
    payload: payload
  }
}

export function authError() {
  return {
    type: 'AUTH_ERROR'
  }
}

export function setAlert(message, alertType, id = uuid.v4()) {
  return {
    type: 'SET_ALERT',
    payload: {
      message: message,
      alertType: alertType,
      uuid: id
    }
  };
}

export function removeAlert(id) {
  return {
    type: 'REMOVE_ALERT',
    payload: id
  };
}

export function logout() {
  return {
    type: 'LOGOUT'
  };
}

export const updateSearchResults = (results) => {
  return {
    type: 'UPDATE_SEARCH_RESULTS',
    results: results
  };
}

export const loadSupplySnapshots = (cameraID, name, images) => {
  return {
    type: 'LOAD_SUPPLY_SNAPSHOTS',
    cameraID: cameraID,
    name: name,
    images: images
  };
}

export const loadSuppliesPreviews = previews => {
  return {
    type: 'LOAD_SUPPLIES_PREVIEWS',
    previews: previews
  };
}

// Action to reset supplySnapshot state
export const resetSupplyIndex = () => {
  return {
    type: 'RESET_SUPPLY_INDEX'
  }
}

// Action to go to the PREVIOUS photo, i.e. a less current photo
export const incrementSupplySnapshotIndex = () => {
  return {
    type: 'ADD_TO_SUPPLY_INDEX',
    value: 1
  }
}

// Action to go to the next photo; i.e., a more current photo
export const decrementSupplySnapshotIndex = () => {
  return {
    type: 'ADD_TO_SUPPLY_INDEX',
    value: -1
  }
}

export const sendSnapshotRequest = (camera) => {
  return {
    type: 'CREATE_SNAPSHOT_REQUEST',
    meta: {remote: true},
    camera: camera
  };
}

// Action to create a mobile recognition request.  
export const createMobileRequest = (encodedImage, requestTimestamp) => {
  return {
    type: "CREATE_MOBILE_REQUEST",
    meta: {remote: true},
    encodedImage: encodedImage,
    requestTimestamp: requestTimestamp
  }
}

export const requestRTCPeerConnectionOffer = (cameraID) => {
  return {
    type: "REQUEST_RTC_PEER_OFFER",
    meta: {remote: true},
    cameraID: cameraID
  };
}

export const sendRTCPeerConnectionAnswer = (cameraID, answer) => {
  return {
    type: "SET_RTC_PEER_CONNECTION_ANSWER",
    meta: {remote: true},
    cameraID: cameraID,
    answer: answer
  };
}

export const sendIceCandidateToRemotePeer = (cameraID, candidate) => {
  return {
    type: "ADD_ICE_CANDIDATE_FOR_CAMERA",
    meta: {remote: true},
    cameraID: cameraID,
    candidate: candidate
  };
}

export const setLivestreamData = (data) => {
  return {
    type: 'SET_LIVESTREAM_DATA',
    data: data
  };
}