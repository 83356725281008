import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as SuppliesActions from '../hooks/supplies';
import * as LivestreamActions from '../hooks/livestream';
import LivestreamContext from '../contexts/livestream-context';
import CameraViewBar from './CameraViewBar';
import CameraStream from './CameraStream';
import SuppliesList from './SuppliesList';

const CameraContainer = styled.div`
	width: 100%;
	background-color: white;
	border: 1px solid #D6E2E6;
	border-radius: 8px;
	overflow: scroll;
`;

const SupplyCamera = ({ match: { params }, supplySnapshots, liveCameras, SuppliesActions, LivestreamActions }) => {	
	useEffect(() => {
		LivestreamActions.requestRTCPeerConnectionOffer('3');
		SuppliesActions.resetSupplyIndex();
		SuppliesActions.getSupplySnapshots(params.id);
	}, [params.id, SuppliesActions, LivestreamActions]);
	
	// Temp code for Newark demo
	const name = supplySnapshots.get('name') || 'Mobile';
	// End temp code

	const stream = useContext(LivestreamContext);
	stream.hello();
	
	const streamStatus = liveCameras.size > 0 ? liveCameras.getIn(['3', 'streamStatus'], 'inactive') : 'inactive'; // replace first element with cameraID 
	const index = supplySnapshots.get('currentIndex');
	const supplies = supplySnapshots.getIn(['images', index, 'supplies']) || [];

	let peerConnection = new RTCPeerConnection();

	peerConnection.onicecandidate = LivestreamActions.handleConnection;

	if (streamStatus === "pending") {

	} else if (streamStatus === "hasOffer") {

	} else if (streamStatus === "hasAnswer") {

	} else if (streamStatus === "hasCandidate") {

	}

	return (
		<CameraContainer>
			<CameraViewBar 
				camName={name} />
			<CameraStream 
				camID={params.id}
				index={index} 
				streamStatus={streamStatus}
				images={supplySnapshots.get('images')}
				suppliesActions={SuppliesActions} />
			<SuppliesList
				supplies={supplies} /> 
		</CameraContainer>
	);
}

const mapStateToProps = state => {
	return {
		supplySnapshots: state.get('supplySnapshots'),
		liveCameras: state.get('cameraLiveStream')
	};
}

const mapDispatchToProps = dispatch => {
	return {
		SuppliesActions: bindActionCreators(SuppliesActions, dispatch),
		LivestreamActions: bindActionCreators(LivestreamActions, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplyCamera);
