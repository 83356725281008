import allIcon from '../img/all-icon.svg';
import suppliesIcon from '../img/supplies-icon.svg';
import tasksIcon from '../img/tasks-icon.svg';

const getIcon = type => {
	switch(type) {
		case 'all': 
			return allIcon;
		case 'supply':
		case 'supplies':
			return suppliesIcon;
		case 'task':
		case 'tasks':
			return tasksIcon;
		default:
			return allIcon;
	}
}

export default getIcon;
