import React from 'react';
import styled from 'styled-components';
import BarGraph from './BarGraph';
import AnalyticsList from './AnalyticsList';

const GraphListContainer = styled.div`
	border: 1px solid #D6E2E6;
	border-radius: 8px;
	padding: .5rem;
	background-color: white;
	overflow: scroll;
`;

const GraphListAnalytics = props => {
	return (
		<GraphListContainer>
			<BarGraph />
			<AnalyticsList />
		</GraphListContainer>
	);
}

export default GraphListAnalytics;
