import React from 'react';
import styled from 'styled-components';
import SidebarLinks from './SidebarLinks';

const SidebarContainer = styled.div`
    grid-area: sb;
    max-height: 100vh;
    padding: 2rem 1rem;
    background-color: #E9F1F3;
`;

const Sidebar = props => {
    return (
        <SidebarContainer className={props.className}>
            <SidebarLinks />
    	</SidebarContainer>
    );
}

export default Sidebar;
