import React from 'react';
import styled from 'styled-components';
import getIcon from '../hooks/icons';

const OptionButton = styled.button`
	width: 100%;
	height: 3rem;
	position: relative;
	background-color: white;
	background-image: url(${props => getIcon(props.name)});
	background-repeat: no-repeat;
	background-position: center;
	&:hover {
		background-color: #D4EAF0;
	}
`;

const SearchFilterOption = ({ value, query, handleClose, handleFilter }) => {
	const handleClick = query => e => {
		handleFilter(e, query);
		handleClose(e);
	}
	
	return (
		<OptionButton 
			className="filter"
			name={value}
		 	onClick={handleClick(query)} />
	);
}

export default SearchFilterOption;
