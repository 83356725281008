import React from 'react';
import { fromJS } from 'immutable';
import styled from  'styled-components';
import ListHeader from './ListHeader';
import SuppliesListRow from './SuppliesListRow';

const suppliesHeaders = fromJS([
    'MFR.',
    'BRAND',
    'NDC',
    'QTY.',
    'LOT #',
    "EXP. \xa0 DATE",
    'REORDER'
]);

const SupplyListContainer = styled.div`
  min-width: 60rem;
  padding: .5rem 0;
  background-color: white;
  border-radius: 0 0 8px 8px;
  overflow-x: scroll;
`;

const SuppliesList = ({ supplies }) => {
  return (
    <SupplyListContainer>
  		<ListHeader headers={suppliesHeaders} />
      {supplies.map((supply, index) => {
        return (
          <SuppliesListRow 
            key={index}
            entries={supply} />
        );
      })}
  	</SupplyListContainer>
  );
}

export default SuppliesList;
