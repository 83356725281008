import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer';
import thunk from 'redux-thunk';
import clientIO from 'socket.io-client'
import { composeWithDevTools } from 'redux-devtools-extension';
import remoteActionMiddleware from './remote-action-middleware';
import * as Actions from './action-creators';

const CENTRAL_SERVER_HOSTNAME = `https://cs.taskunite.com`
// const CENTRAL_SERVER_HOSTNAME = `http://localhost`
const PORT_NUMBER = '8081'
const socket = clientIO(`${CENTRAL_SERVER_HOSTNAME}:${PORT_NUMBER}`);

socket.on('connect', () => {
	console.log('connection made!');
})
socket.on('updateState', state => {
	console.log(JSON.stringify(state, null, 2));
	store.dispatch(Actions.loadSuppliesPreviews(state.shelfPreviews)); // Update to load snapshots as well.
})

socket.on('state', state => {
	console.log("state; " + JSON.stringify(state.liveCameras, null, 2));
	store.dispatch(Actions.setLivestreamData(state.liveCameras));
	console.log(JSON.stringify(store.getState(), null, 2));
})

const middleware = [thunk, remoteActionMiddleware(socket)];

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)));

export default store;