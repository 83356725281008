import React from 'react';
import styled from 'styled-components';
import getIcon from '../hooks/icons';
import DropArrow from './DropArrow';

const ButtonContainer = styled.div`
	grid-area: f;
	display: flex;
	align-items: center;
	padding: 0 .5rem;
	height: 100%;
	&:hover {
		background-color: #D4EAF0;
	}
`;

const SearchFilterButton = ({ filter, handleOpen }) => {
	return (
		<ButtonContainer className="filter" onClick={handleOpen}>
			<img 
				className="filter"
				alt={`Filter by: ${filter}`} 
				src={getIcon(filter)} />
			<DropArrow className="filter" />
		</ButtonContainer>	
	);
}

export default SearchFilterButton; 
