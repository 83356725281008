import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const LogoLinkContainer = styled.div`
	display: flex;
	align-items: center;
	padding: .3rem;
	transition: .3seconds;
	&:hover {
		background-color: #F3FDFF;
		outline: 1px solid #D6E2E6;
	}
`;

const LinkLogo = styled.img.attrs(props => ({
	src: props.src
}))`
	height: 1.9rem;
	width: 1.9rem;
	margin-right: 1rem;
`;

const activeStyle = {
	background: '#F3FDFF',
	outline: '1px solid #D6E2E6'
};

const LogoLink = ({ logoSrc, to, children }) => {
	return (
		<NavLink activeStyle={activeStyle} to={to}>
			<LogoLinkContainer>
				<LinkLogo src={logoSrc} />
				{children}
			</LogoLinkContainer>	
		</NavLink>
	);
}

export default LogoLink;
