import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import SearchResult from './SearchResult';

const ResultsListContainer = styled.div`
	position: absolute;
	z-index: 2;
	grid-area: r;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 20rem;
	overflow: scroll;
	background-color: white;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;

const SearchResultsList = ({ results, query, filter, handleClose }) => {
	const node = useRef();
	const handleClick = e => {
		const target = e.target;
		if (node.current.contains(target) || target.className.includes('filter')) {
			return;
		} else { handleClose(); }
	};
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		}
	});
	
	return (
		<ResultsListContainer id="results" ref={node}>
			{results && results.size < 1 && 
				<SearchResult 
					name={`"${query}" not found`} 
					type={filter}
					linkURL=""
					handleClose={handleClose} />}
			{results && results.map((result, index) => {
				return (
					<SearchResult 
						key={index} 
						name={result.get('text')} 
						type={result.get('type')}
						linkURL={'/analytics'} 
						handleClose={handleClose} />
				);
			})}
		</ResultsListContainer>
	);
};

export default SearchResultsList;
