import React, { Component } from 'react';
import styled from 'styled-components';

const XContainer = styled.div`
	grid-area: x;
	display: flex;
	justify-content: space-between;
	padding: .5rem 1rem 0 1rem;
`;

const XLabel = styled.h1`
	width: 5rem;
	text-align: center;
`;

class XAxis extends Component {
  render() {
    return (
    	<XContainer className={this.props.className}>
    		<XLabel>7/4/19</XLabel>
    		<XLabel>7/5/19</XLabel>
    		<XLabel>7/6/19</XLabel>
    		<XLabel>7/7/19</XLabel>
    		<XLabel>7/8/19</XLabel>
    		<XLabel>7/9/19</XLabel>
    		<XLabel>7/10/19</XLabel>
    	</XContainer>
    );
  }
}

export default XAxis;