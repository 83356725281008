import React from 'react';
import styled from 'styled-components';

const UserIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 0.05rem;
	height: 2.5rem;
	width: 2.5rem;
	margin-right: 0.75rem;
	background: #FFFFFF;
	font-size: 1.2rem;
	border-radius: 50%;
	font-family: Helvetica;
	color: #1E8DA6;
`; 

const Account = props => {
	return (
		<UserIcon>{props.name}</UserIcon>
	);
}

export default Account;
