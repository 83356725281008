import React from 'react';
import styled from 'styled-components';
import LogoLink from './LogoLink';
import dashboardIcon from '../img/dashboard-icon.svg';
import suppliesIcon from '../img/supplies-icon.svg';
import tasksIcon from '../img/tasks-icon.svg';
import analyticsIcon from '../img/analytics-icon.svg';
import scannerIcon from '../img/scanner-icon.svg';

const LinkContainer = styled.div`
    & > * {
        margin-top: 0.25rem;
    }
    &:first-child {
        margin-top: 0;
    }
`;

const SidebarLinks = props => {
	return (
		<LinkContainer>
            <LogoLink logoSrc={dashboardIcon} to="/dashboard">Dashboard</LogoLink>
            <LogoLink logoSrc={suppliesIcon} to="/supplies">Supplies</LogoLink>
            <LogoLink logoSrc={tasksIcon} to="/tasks">Tasks</LogoLink>
            <LogoLink logoSrc={analyticsIcon} to="/analytics">Analytics</LogoLink>
            <LogoLink logoSrc={scannerIcon} to="/scanner">Scanner</LogoLink>
		</LinkContainer>
	);
}

export default SidebarLinks;
