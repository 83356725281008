import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './utilities/PrivateRoute';
import App from './components/App';
import Login from './components/Login';
import Register from './components/Register';

const homePaths = [
	'/', '/home', '/dashboard',
];

const appPaths = [
	'/tasks',
	'/supplies',
	'/analytics',
	'/scanner'
];

const Root = ({ isAuthed, className }) => {
	return (
		<Switch>
			<PrivateRoute exact path={homePaths} component={App} isAuthed={isAuthed} />
			<PrivateRoute path={appPaths} component={App} isAuthed={isAuthed} />
			<Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route component={Register} />
		</Switch>
	);
};

const mapStateToProps = state => ({
  isAuthed: state.getIn(['auth', 'isAuthenticated'])
});

export default connect(mapStateToProps)(Root);
