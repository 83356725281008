import React from 'react';
import styled from 'styled-components';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import MainPanel from './MainPanel';

const NAV_HEIGHT = 58;

const AppContainer = styled.div`
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-template-rows: auto calc(100vh - ${NAV_HEIGHT}px);
  grid-template-areas: "tb tb"
                       "sb mn";
  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas: "tb"
                         "sb"
                         "mn"
                         ".";
  }
`;

const App = ({ className }) => {
  return (
      <AppContainer className={className}>
        <Topbar className="Topbar" />
        <Sidebar className="Sidebar" />
        <MainPanel className="MainPanel" />
      </AppContainer>
  );
}

export default App;
