import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numHeaders}, minmax(15rem, 100%));
  grid-template-rows: 2rem;
  padding: 1rem;
  font-weight: bold;
`;

const ListHeader = ({ headers }) => {
  return (
  	<HeaderContainer numHeaders={headers.size}> 
      {headers.map((header, index) => (
        <h1 key={index}>{header}</h1>
      ))}
  	</HeaderContainer>
  );
}

export default ListHeader;
