import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import CameraGrid from './CameraGrid';
import SupplyCamera from './SupplyCamera';

const Supplies = props => {
  useEffect(() => {
    document.title = "Supplies";
  });
  
  return (
    <Switch>
      <Route exact path="/supplies" component={CameraGrid} />
      <Route exact path="/supplies/:id" component={SupplyCamera} />
    </Switch>
  );
}

export default Supplies;
