import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import SearchFilterOption from './SearchFilterOption';

const SelectorContainer = styled.div`
	position: absolute;
	z-index: 3;
	display: flex;
	flex-direction: column;
	width: 100%;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;

const SearchFilterSelector = ({ query, handleClose, handleFilter }) => {
	const node = useRef();
	const handleClick = e => {
		if (node.current.contains(e.target)) {
			return;
		} else { handleClose(); }
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		}
	});

	return (
		<SelectorContainer ref={node} className="filter">
			{['all', 'supplies', 'tasks'].map((option, index) => {
				return (
					<SearchFilterOption 
						key={index}
						className="filter"
						value={option}
						query={query}
						handleClose={handleClose}
						handleFilter={handleFilter} />
				);
			})}	
		</SelectorContainer>
	);
}

export default SearchFilterSelector; 
