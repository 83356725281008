import { fromJS } from 'immutable';

export function setTitle(state, title) {
  return state.set('title', title); 
}

export function addTask(tasksState, task) {
  return tasksState.set(tasksState.size + 1, fromJS(task));
}

export function registerSuccess(authState, token) {
  return authState.set('token', fromJS(token));
}

export function userLoaded(authState, user) {
  let userState = authState.set('user', fromJS(user));
  return userState.set('isAuthenticated', true);
}
