import React from 'react';
import { Map } from 'immutable';
import styled from 'styled-components';
import SupplyTimestamp from './SupplyTimestamp';
import SupplyStream from './SupplyStream';
import SupplyStreamControls from './SupplyStreamControls';

const StreamContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr 1fr;
	grid-template-rows: auto 1fr auto;
	grid-template-areas: "t . ."
						 ". . ."
						 "c c c";
`;

const CameraStream = ({ camID, index, streamStatus, images, suppliesActions }) => {
	const numImages = images.size;
	const snapshot = images.get(index) || Map();
	let streamSrc = snapshot.get('url');
	let snapshotTimestamp = snapshot.get('timestamp');
	
	if (streamStatus === "live") {
		streamSrc = 'live source';
	}
	
	return (
		<StreamContainer>
			<SupplyTimestamp
				streamStatus={streamStatus}
				timestamp={snapshotTimestamp} />
			<SupplyStream
				streamStatus={streamStatus}
				src={streamSrc} />
			<SupplyStreamControls
				streamStatus={streamStatus}
				camID={camID}
				index={index}
				numImages={numImages}
				suppliesActions={suppliesActions}
				startStream={() => console.log('start')} />
		</StreamContainer>
	);
}

export default CameraStream;
