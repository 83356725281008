import React from 'react';
import { fromJS } from 'immutable';
import styled from  'styled-components';
import { Link } from 'react-router-dom';
import ListHeader from './ListHeader';
import ListRow from './ListRow';

const analyticsHeaders = fromJS([
    'DATE',
    'QUANTITY',
    'LOCATION',
    'SHELF'
]);

const supplies1 = fromJS({
    1: '7/10/19',
    2: '5',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const supplies2 = fromJS({
    1: '7/9/19',
    2: '5',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const supplies3 = fromJS({
    1: '7/8/19',
    2: '6',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const supplies4 = fromJS({
    1: '7/7/19',
    2: '7',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const supplies5 = fromJS({
    1: '7/6/19',
    2: '9',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const supplies6 = fromJS({
    1: '7/5/19',
    2: '9',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const supplies7 = fromJS({
    1: '7/4/19',
    2: '10',
    3: 'Clinic Refrigerator',
    4: 'Shelf 2'
});

const ListContainer = styled.div`
  border-radius: 0 0 8px 8px;
  padding: .5rem 0;
  min-width: 60rem;
  background-color: white;
  overflow: scroll;
`;

const AnalyticsList = props => {
  return (
    <ListContainer className={props.className}>
  		<ListHeader headers={analyticsHeaders} />
      <Link to="supplies/2">
        <ListRow entries={supplies1} />
      </Link>
      <Link to="supplies/2">
        <ListRow entries={supplies2} />
      </Link>
      <Link to="supplies/2">
        <ListRow entries={supplies3} />
      </Link>
      <Link to="supplies/2">
        <ListRow entries={supplies4} />
      </Link>
      <Link to="supplies/2">
        <ListRow entries={supplies5} />
      </Link>
      <Link to="supplies/2">
        <ListRow entries={supplies6} />
      </Link>
      <Link to="supplies/2">
        <ListRow entries={supplies7} />
      </Link>
  	</ListContainer>
  );
}

export default AnalyticsList;
