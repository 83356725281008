import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PanelContainer from './PanelContainer';
import Dashboard from './Dashboard';
import Tasks from './Tasks';
import Supplies from './Supplies';
import Analytics from './Analytics';
import Scanner from './Scanner';

const MainPanel = props => {
    return (
        <PanelContainer>
            <Switch>
        		<Route exact path={['/dashboard', '/home', '/']} component={Dashboard} />
        		<Route exact path="/tasks" component={Tasks} />
        		<Route exact path={['/supplies', '/supplies/:id']} component={Supplies} />
        		<Route exact path="/analytics" component={Analytics} />
                <Route exact path="/scanner" component={Scanner} />
            </Switch>
        </PanelContainer>
    );
}

const mapStateToProps = state => ({
	isAuthenticated: state.get('auth').get('isAuthenticated')
});

export default connect(mapStateToProps, null)(MainPanel);
