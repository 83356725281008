import React from 'react';
import styled from 'styled-components';
import ToggleContent from './ToggleContent';
import Modal from './Modal';

const GRAPH_SCALER = 2.5;

const BarContainer = styled.div`
  height: ${props => props.quantity * GRAPH_SCALER}rem;
  width: 5rem;
  background-color: #D8D8D8;
  transition: background-color .5s;
  &:hover {
    background-color: darkGrey;
  }
`;

const SupplyBar = ({ quantity }) => {
  const Toggle = handleOpen => {
    return <BarContainer quantity={quantity} onClick={handleOpen} />;
  }
  
  const Content = handleClose => {
    return (
      <Modal handleClose={handleClose}>
        <h1>{quantity}</h1>
      </Modal>
    );
  }

  return (
    <ToggleContent
      toggleComp={Toggle}
      contentComp={Content}
    />
  );
}

export default SupplyBar;
