import React, { Fragment, useEffect } from 'react';
import Alert from './Alert';
import yellowSuppliesIcon from '../img/yellow-supplies-icon.svg';
import orangeSuppliesIcon from '../img/orange-supplies-icon.svg';
import redSuppliesIcon from '../img/red-supplies-icon.svg';
import PanelButton from './PanelButton';
import PanelMenu from './PanelMenu';

const Dashboard = props => {
  useEffect(() => {
    document.title = 'Dashboard';
  });

  return (
    <Fragment>
      <PanelMenu title="Dashboard"> 
        <PanelButton>Add Widget</PanelButton>
      </PanelMenu>
      <Alert iconSrc={redSuppliesIcon} message="You are out of gowns" />
      <Alert iconSrc={orangeSuppliesIcon} message="You're on pace to run out of gowns by April 4th" />
      <Alert iconSrc={yellowSuppliesIcon} message="Gowns are depleting faster than normal" />
    </Fragment>
  );
}

export default Dashboard;
