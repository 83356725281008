import React from 'react';
import styled from 'styled-components';

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.numValues}, minmax(15rem, 1fr));
  grid-template-rows: auto;
  padding: .5rem 1rem;
  &:hover {
    background-color: #D4EAF0;
  }
`;

const ListRow = ({ entries }) => {
  const values = entries.valueSeq() || [];
  return (
  	<ListContainer numValues={values.size}> 
  		{values.map((value, index) => (
        <p key={index}>{value}</p>
      ))}
  	</ListContainer>
  );
}

export default ListRow;
