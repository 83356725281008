import React from 'react';
import styled from 'styled-components';
import SuppliesValue from './SuppliesValue';
import ReorderButton from './SuppliesReorderButton';

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => ++props.numValues}, minmax(15rem, 100%));
  grid-template-rows: auto;
  padding: 1rem;
  min-width: 106rem;
  &:hover {
    background-color: #D4EAF0;
  }
`;

const SuppliesListRow = ({ entries }) => {
  const values = entries.valueSeq() || [];
  return (
  	<ListContainer numValues={values.size}> 
	  	{values.map((value, index) => (
        <SuppliesValue key={index}>
          <p>{value}</p>
        </SuppliesValue>
	    ))}
  		<ReorderButton>order more</ReorderButton>
  	</ListContainer>
  );
}

export default SuppliesListRow;
