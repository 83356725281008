import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	grid-area: mn;
  	overflow: scroll;
  	background-color: #E9F1F3;
  	padding: 2rem 1rem 2rem 0;
  	height: 100%;
  	@media (max-width: 600px) {
	  	padding: 0 1rem 2rem 1rem;
	}
`;

const PanelContainer = ({ children }) => {
	return (
		<Container>
			{children}
		</Container>
	);
};

export default PanelContainer;
