import React from 'react';
import styled from 'styled-components';

const SuppliesImage = styled.img.attrs(props => ({
	src: props.src,
	alt: "Supply Image"
}))`
	display: block;
	grid-row: 1 / 4;
	grid-column: 1 / 4;
	width: 100%;
	background-color: black;
`;

const SuppliesVideo = styled.video.attrs(props => ({
	src: props.src,
	alt: "Supply Video"
}))`
	display: block;
	grid-row: 1 / 4;
	grid-column: 1 / 4;
	width: 100%;
	max-height: 20rem;
	background-color: black;
`;

const SupplyStream = ({ streamStatus, src }) => {
	if (streamStatus === "live") {
		return <SuppliesVideo src={src} />
	} else {
		return <SuppliesImage src={src} />
	}
};

export default SupplyStream;

