import React from 'react';
import styled from 'styled-components';
import BackLink from './BackLink';

const BarContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto; 
	align-items: center;
	padding: 1.25rem 1rem;
`;

const BarTitle = styled.h1`
	text-align: center;
	font-size: 1.5rem;
`;

const CameraViewBar = ({ camName }) => {
	return (
		<BarContainer>
			<BackLink 
				to="/supplies" />
			<BarTitle>{camName}</BarTitle>
		</BarContainer>
	);
}

export default CameraViewBar;
