import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../hooks/auth'; 
import AuthAlert from './AuthAlert'
import styled from 'styled-components';
import { setTitle } from '../action-creators';

const LoginContainer = styled.div`
  grid-area: au;
  overflow: scroll;
  background-color: #E9F1F3;
  padding: 2rem 1rem;
`;

const LoginTitle = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const Login = ({ login, isAuthenticated, className }) => {
  useEffect(() => {
		setTitle('Login');
  });
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <LoginContainer className={ className }>
      <AuthAlert />
      <LoginTitle>Login</LoginTitle>
      <form className='form' onSubmit={e => onSubmit(e)}>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
            onChange={e => onChange(e)}
            required
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Password'
            name='password'
            value={password}
            onChange={e => onChange(e)}
            minLength='6'
          />
        </div>
        <input type='submit' className='authButton' value='Login' />
      </form>
      <p>
        Don't have an account? <Link to='/register'>Sign Up</Link>
      </p>
    </LoginContainer>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.get('auth').get('isAuthenticated')
});

export default connect(
  mapStateToProps,
  { login, setTitle }
)(Login);
