export const since = timestamp => {
	const eventTime = new Date(timestamp);
	let currentTime = new Date();
    let delta = parseInt((currentTime.getTime() - eventTime.getTime()) / 1000);
	
    if (delta < 60) { return 'Just now'; }
    else if (delta < 120) { return 'About 1 minute ago'; }
    else if (delta < (60 * 60)) { return (parseInt(delta / 60)).toString() + ' minutes ago'; }
    else if (delta < (120 * 60)) { return '1 hour ago'; }
    else if (delta < (24 * 60 * 60)) { return (parseInt(delta / 3600)).toString() + ' hours ago'; }
    else if (delta < (48 * 60 * 60)) { return '1 day ago'; }
    else return (parseInt(delta / 86400)).toString() + ' days ago'; 
}

export const current = timestamp => {
	const localTime = new Date(timestamp);
	const date = localTime.toLocaleDateString();
	let hours = localTime.getHours();
	let minutes = localTime.getMinutes();
	let seconds = localTime.getSeconds();
	
	if (hours < 10) { hours = prependZero(hours); }
	if (minutes < 10) { minutes = prependZero(minutes); }
	if (seconds < 10) { seconds = prependZero(seconds); }
	
	return `${date} @ ${hours}:${minutes}:${seconds}`;
}

const prependZero = numericStr => {
	return '0' + numericStr;
}
