import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import backArrow from '../img/back-arrow.svg';

const BackLinkContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const BackArrow = styled.img.attrs(props => ({
	src: props.src
}))`
	height: 1.25rem;
	margin-right: .5rem;
`;

const BackLink = ({ to }) => {
	return (
		<Link to={to}>
			<BackLinkContainer>
				<BackArrow 
					alt="back arrow" 
					src={backArrow} />
				<h2>back</h2>
			</BackLinkContainer>
		</Link>
	);
}

export default BackLink;
