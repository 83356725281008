import React from 'react';
import styled from 'styled-components';
import GraphContainer from './GraphContainer';
import SupplyBars from './SupplyBars';
import XAxis from './XAxis';
import YAxis from './YAxis';

const GraphTitle = styled.h1`
    grid-area: t;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
`;

const BarGraph = ({ className }) => {
    return (
        <GraphContainer className={className}>
            <GraphTitle>Menactra Vaccine</GraphTitle>
            <YAxis />
            <XAxis />
            <SupplyBars />
        </GraphContainer>
    );
}

export default BarGraph;
