import React from 'react';
import styled from 'styled-components';
import alert from '../img/alert-icon.svg';

const AlertIcon = styled.img.attrs({
	src: alert
})`
	height: 2.5rem;
	width: 2.21rem;
`;

const Alerts = props => {
	return <AlertIcon />;
}

export default Alerts;
