import React, { Component } from 'react';
import styled from 'styled-components';
import SupplyBar from './SupplyBar';

const BarsContainer = styled.div`
	grid-area: b;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
    height: 30rem;
    padding: 0 1rem;
    border-left: 1px solid #979797;
    border-bottom: 1px solid #979797;
`;

class SupplyBars extends Component {
  render() {
    return (
    	<BarsContainer className={this.props.className}>
    		<SupplyBar quantity="10" supplyName="Menactra Vaccine" />
    		<SupplyBar quantity="9" supplyName="Menactra Vaccine" />
    		<SupplyBar quantity="9" supplyName="Menactra Vaccine" />
    		<SupplyBar quantity="7" supplyName="Menactra Vaccine" />
    		<SupplyBar quantity="6" supplyName="Menactra Vaccine" />
    		<SupplyBar quantity="5" supplyName="Menactra Vaccine" />
    		<SupplyBar quantity="5" supplyName="Menactra Vaccine" />
    	</BarsContainer>
    );
  }
}

export default SupplyBars;
