import React from 'react';
import styled from 'styled-components';
import searchIcon from '../img/search-icon.svg';

const IconContainer = styled.div`
	grid-area: i;
	width: 2.5rem;
	height: 100%;
	background-color: white;
	background-image: url(${searchIcon});
	background-repeat: no-repeat;
	background-position: center;
`;

const SearchIcon = props => {
	return <IconContainer />
}

export default SearchIcon; 
