import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
`;

const ModalContainer = styled.div`
	width: 60rem;
	padding: 0.75rem;
	background: white;
`;

const Modal = ({ handleClose, children }) => (
	createPortal(
		<ModalBackdrop className="backdrop" onClick={handleClose}>
	  		<ModalContainer>
	  			{children}
	  			<button onClick={handleClose} className="closer">Close</button>
	  		</ModalContainer>
  		</ModalBackdrop>
	, document.body)
)

export default Modal;
