import styled from 'styled-components';
import dropArrowIcon from '../img/drop-arrow.svg';

const DropArrow = styled.img.attrs({
	src: dropArrowIcon
})`
	padding-left: .5rem;
`;

export default DropArrow;
