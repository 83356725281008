import React from 'react';
import { fromJS } from 'immutable';
import styled from  'styled-components';
import ListHeader from './ListHeader';
import ListRow from './ListRow';

const taskHeaders = fromJS([
    'TASK',
    'SUPPLIES',
    'OWNER',
    'LOCATION',
    'DUE'
]);

const task = fromJS({
    1: 'Administer flu shot',
    2: 'In stock',
    3: 'Adrian R',
    4: 'Room 202',
    5: '2pm'
});

const TaskListContainer = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #D6E2E6;
  border-radius: 8px;
  overflow: scroll;
`;

const TaskList = props => {
  return (
  	<TaskListContainer className={props.className}>
  		<ListHeader headers={taskHeaders} />
      <ListRow entries={task} />
      <ListRow entries={task} />
      <ListRow entries={task} />
      <ListRow entries={task} />
      <ListRow entries={task} />
      <ListRow entries={task} />
      <ListRow entries={task} />
  	</TaskListContainer>
  );
}

export default TaskList;
