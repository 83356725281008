import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Time from '../hooks/time';
import PreviewImage from './CameraPreviewImage';
import PreviewBar from './CameraPreviewBar';

const PreviewContainer = styled.div`
	height: 20rem;
	background-color: white;
	border: 1px solid #D6E2E6;
	border-radius: 8px;
`;

const CameraPreview = ({ id, name, imageSrc, imageTimestamp }) => {
	return (
		<Link to={`supplies/${id}`}>
			<PreviewContainer>
				<PreviewImage alt="hello" src={imageSrc} />
				<PreviewBar name={name} time={Time.since(imageTimestamp)} />
			</PreviewContainer>
		</Link>
	);
}

export default CameraPreview;
