import axios from 'axios';
import * as actions from '../action-creators';

// export const addSupply = async ({ item_id, name, remaining, last_updated }) => {
//     if (item_id) {
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }
//         const body = JSON.stringify({ item_id, name, remaining, last_updated });

//         try {
//             const response = await axios.post('https://dev.taskunite.com/api/supplies', body, config);
//         } catch (err) {
//             const errors = err.response.data.errors;
//             console.log(`Error: ${errors}`);
//             // dispatch error action
//             throw err;
//         }
//     }
// }

export const getSupplySnapshots = (cameraID, offsetFromLatest = 0, batchSize = -1) => async dispatch => {
    if (cameraID) {
        const config = {
            params: {
                offsetFromLatest: offsetFromLatest,
                batchSize: batchSize
            }
        };

        try {
            const res = await axios.get(`https://dev.taskunite.com/api/supplies/camera/${cameraID}`, config);
            dispatch(actions.loadSupplySnapshots(cameraID, res.data.camera.name, res.data.camera.images));
        } catch (err) {
            console.log(`Error: ${err.message}`);
            throw err;
        }
    }
}

export const getSuppliesPreviews = () => async dispatch => {
    try {
        const res = await axios.get('https://dev.taskunite.com/api/previews');
        dispatch(actions.loadSuppliesPreviews(res.data.previews));
    } catch (err) {
        console.log(`Error: ${err.message}`);
        throw err;
    }
}

export const getPrevSnapshot = (cameraID, currIndex, numImages) => dispatch => {
    if (currIndex < numImages - 1) {
        dispatch(actions.incrementSupplySnapshotIndex());
    } else {
        console.log('no more prev');
    }
}

export const getNextSnapshot = (cameraID, currIndex, numImages) => dispatch => {
    if (currIndex > 0) {
        dispatch(actions.decrementSupplySnapshotIndex());
    } else {
        console.log('no more next');
    }
}

export const getNewSnapshot = () => dispatch => {
    dispatch(actions.sendSnapshotRequest());
    dispatch(actions.resetSupplyIndex());
}

export const resetSupplyIndex = () => dispatch => {
    dispatch(actions.resetSupplyIndex());
}

export const getVideoData = () => async dispatch => {
    try {
        
    } catch {
        
    }
}
