// import adapter from 'webrtc-adapter';
import * as actions from '../action-creators';

export const requestRTCPeerConnectionOffer = () => async dispatch => {
    dispatch(actions.requestRTCPeerConnectionOffer);
}

// const handleConnection = event => async dispatch => {
//     const iceCandidate = event.candidate;
  
//     if (iceCandidate) {
//       const newIceCandidate = new RTCIceCandidate(iceCandidate);
//       // send candidate to central server
//       dispatch(actions.sendIceCandidateToRemotePeer('cameraID', newIceCandidate));    

//     }
//   }

// const onReceiveOffer = (offerDescription) => async dispatch => {
//     let peerConnection = new RTCPeerConnection();
//     peerConnection.onicecandidate = handleConnection;
    
//     try {
//         await peerConnection.setRemoteDescription(offerDescription);
//     } catch (e) {
//         console.log(`Failed to set remote description`);
//     }

//     // Create answer

//     try {
//         const answer = await peerConnection.createAnswer();
//         peerConnection.setLocalDescription(answer);
//         // send answer to central server so they can set it as remote description
//         dispatch(actions.sendRTCPeerConnectionAnswer('cameraID', answer));
//         // send back to caller
//     } catch (e) {

//     }
    
// };
