import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { register } from '../hooks/auth'; // change to action?
import styled from 'styled-components';
import { setAlert } from '../action-creators'
import AuthAlert from './AuthAlert';
import { setTitle } from '../action-creators';

const RegisterContainer = styled.div`
  grid-area: au;
  overflow: scroll;
  background-color: #E9F1F3;
  padding: 2rem 1rem;
`;

const RegisterTitle = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 2rem;
`;

const Register = ({ register, setAlert, isAuthenticated }) => {
  useEffect(() => {
    setTitle('Register');
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    facility: '1'
  });

  const { name, email, password, password2, facility } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register({ name, email, password, facility});
    }
  };


  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <RegisterContainer className='Register'>
      <AuthAlert />
      <RegisterTitle>Register</RegisterTitle>
      <p className='lead'>
        <i className='fas fa-user' /> Create Your Account
      </p>
      <form className='form' onSubmit={e => onSubmit(e)}>
        <div className='form-group'>
          <input
            type='text'
            placeholder='Name'
            name='name'
            value={name}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='email'
            placeholder='Email Address'
            name='email'
            value={email}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Password'
            name='password'
            value={password}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <input
            type='password'
            placeholder='Confirm Password'
            name='password2'
            value={password2}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
          <select name='facility' value={facility} onChange={e => onChange(e)}>
            <option value="1">Stanford Healthcare - Stanford, CA</option>
            <option value="2">Cedars-Sinai Medical Center - Los Angeles, CA</option>
            <option value="3">Coastal Healthcare - Brick, NJ</option>
          </select>
        </div>
        <input type='submit' className='authButton' value='Register' />
      </form>
      <p className='my-1'>
        Already have an account? <Link to='/login'>Sign In</Link>
      </p>
    </RegisterContainer>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.get('auth').get('isAuthenticated')
});

export default connect(
  mapStateToProps,
  { setAlert, register, setTitle }
)(Register);