import React from 'react';
import styled from 'styled-components';
import NavLogo from './NavLogo';
import AsyncSearch from './AsyncSearch';

const LogoSearchContainer = styled.div`
	display: flex;
	align-items: center;
`;

const LogoAndSearch = props => {
	return (
		<LogoSearchContainer>
			<NavLogo to="/home" />
			<AsyncSearch />
		</LogoSearchContainer>
	);
}

export default LogoAndSearch;
