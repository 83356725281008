import React from 'react';
import styled from 'styled-components';
import LogoAndSearch from './LogoAndSearch';
import AlertsAndAccount from './AlertsAndAccount';

const TopbarContainer = styled.div`
    position: relative;
    z-index: 2;
    grid-area: tb;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ls aa;
    padding: 1rem 1rem;
    background-color: #1E8DA6;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.50);
`;

const Topbar = props => {
  return (
    <TopbarContainer className={props.className}>
    	<LogoAndSearch />
      <AlertsAndAccount />
    </TopbarContainer>
  );
}

export default Topbar; 
