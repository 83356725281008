import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from '../img/logo.svg';

const Logo = styled.img.attrs({
	src: logo
})`
	height: 3.5rem;
	width: 3.5rem;
`;

const NavLogo = ({ to }) => {
	return (
		<NavLink to={to} >
			<Logo />
		</NavLink>
	);
}

export default NavLogo;
