import React, { Fragment, useEffect } from 'react';
import TaskList from './TaskList';
import PanelMenu from './PanelMenu';
import PanelButton from './PanelButton';

const TasksHeader = () => (
  <PanelMenu title="Tasks"> 
    <PanelButton>Add Task</PanelButton>
  </PanelMenu>
);

const Tasks = props => {
  useEffect(() => {
    document.title = 'Tasks';
  });
  
  return (
    <Fragment>
    	<TasksHeader />
      <TaskList title="My Tasks"/>
    </Fragment>
  );
}

export default Tasks;
