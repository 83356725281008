import React, { Fragment } from 'react';
import styled from 'styled-components';
import * as Time from '../hooks/time';

const TimestampContainer = styled.div`
	position: relative;
	z-index: 1;
	grid-area: t;
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	margin-top: 1rem;
	padding: .5rem .75rem;
	width: auto;
	background-color: #F1FDFF;
	opacity: .75;
	border-radius: 6px;
`;

const ElapsedTime = styled.h1`
	font-weight: bold;
	margin-bottom: .5rem;
`;

const LiveStamp = styled.h1`
	font-weight: bold;
	color: #FF9328
`;

const SupplyTimestamp = ({ streamStatus, timestamp }) => {
	return (
		<TimestampContainer>
			{streamStatus === "live" ? (
				<LiveStamp>LIVE</LiveStamp>
			) : (
				<Fragment>
					<ElapsedTime>{Time.since(timestamp)}</ElapsedTime>
					<h1>{Time.current(timestamp)}</h1>
				</Fragment>
			)}
		</TimestampContainer>
	);
}

export default SupplyTimestamp;
