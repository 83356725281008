import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Button = styled.button`
	width: 7.5rem;
	height: 3rem;
	border: 1px solid darkGrey;
	border-radius: 5px;
	:hover {
		border-color: #1E8DA6;
		background-color: #1E8DA6;
		color: white;
		font-weight: bold;
	}
`;

const ReorderButton = ({ children }) => {
	return (
		<ButtonContainer>
			<Button>{children}</Button>
		</ButtonContainer> 
	);
}

export default ReorderButton;
