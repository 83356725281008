import React, { useState } from 'react';
import styled from 'styled-components';
import SearchFilterButton from './SearchFilterButton';
import SearchFilterSelector from './SearchFilterSelector';

const FilterContainer = styled.div`
	position: relative;
	grid-area: f;
	background-color: white;
`;

const SearchFilter = ({ filter, query, handleFilter }) => {
	const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
	const handleOpen = () => setDropdownIsVisible(true);
	const handleClose = () => setDropdownIsVisible(false);

	return (
		<FilterContainer className="filter">
			<SearchFilterButton 
				className="filter" 
				filter={filter} 
				handleOpen={handleOpen} />
			{dropdownIsVisible && <SearchFilterSelector 
				className="filter"
				query={query}
				handleClose={handleClose}
				handleFilter={handleFilter} />}
		</FilterContainer>	
	);
}

export default SearchFilter; 
