import axios from 'axios';
import * as actions from '../action-creators';
import { fromJS } from 'immutable';

const DEFAULT_STATE = fromJS({
    supplies: [],
    tasks: []
}); 

export const all = queryTerms => async dispatch => {
    if (queryTerms) {
        try {
            const config = {
                params: {
                    terms: queryTerms
                }
            };
            const res = await axios.get('https://dev.taskunite.com/api/search', config);
            dispatch(actions.updateSearchResults(res.data));
            return res.data;
        } catch (err) {
            console.log("Error occurred: " + err.message);
            dispatch(actions.updateSearchResults(DEFAULT_STATE));
            return err;
        }
    }
}

export const supplies = queryTerms => async dispatch => {
    if (queryTerms) {
        try {
            const config = {
                params: {
                    terms: queryTerms
                }
            };

            const res = await axios.get('https://dev.taskunite.com/api/search/supplies', config);
            dispatch(actions.updateSearchResults(res.data));
            return res.data;
        } catch (err) {
            console.log("Error occurred: " + err.message);
            dispatch(actions.updateSearchResults(DEFAULT_STATE));
            return err;
        }
    }
}

export const tasks = queryTerms => async dispatch => {
    if (queryTerms) {
        try {
            const config = {
                params: {
                    terms: queryTerms
                }
            };

            const res = await axios.get('https://dev.taskunite.com/api/search/tasks', config);
            dispatch(actions.updateSearchResults(res.data));
            return res.data;
        } catch (err) {
            console.log("Error occurred: " + err.message);
            dispatch(actions.updateSearchResults(DEFAULT_STATE));
            return err;
        }
    }
}

// const getSupply = ({ supplyID }) => async dispatch => {
//     if (supplyID) {
//         try {
//             const res = await axios.get(`https://dev.taskunite.com/api/supplies/${supplyID}`);
//             // TODO: dispatch appropriate action
//             return res.data;
//         } catch (err) {
//             console.log("Error occurred: " + err.message);
//             return err;
//         }
//     }
// }

// const getTask = ({ taskID }) => async dispatch => {
//     if (taskID) {
//         try {
//             const res = await axios.get(`https://dev.taskunite.com/api/tasks/${taskID}`);
//             // TODO: dispatch appropriate action
//             return res.data;
//         } catch (err) {
//             console.log("Error occurred: " + err.message);
//             return err;
//         }
//     }
// }
