import React from 'react';
import styled from 'styled-components';

const SearchInput = styled.input.attrs({
	type: "text",
	placeholder: "Search"
})`
	grid-area: b;
	width: 20rem;
	height: 100%;
	padding-left: 0.5rem;
	font-size: 1.5rem;
	background: white;
	border: none;
	border-radius: 0;
	&:focus {
		outline: 1px solid white;
	}
	@media (max-width: 600px) {
		width: 13rem;
	}
`;

const Search = ({ filter, handleQuery }) => {
	const handleChange = filter => e => {
		handleQuery(e, filter);
	}
	
	return (
		<SearchInput 
			onChange={handleChange(filter)} 
			onClick={handleChange(filter)}
		/>
	);
}

export default Search;
