import React, { Fragment } from 'react';
import styled from 'styled-components';
import prevIcon from '../img/prev-icon.svg';
import nextIcon from '../img/next-icon.svg';
import cameraIcon from '../img/camera-icon.svg';
import videoIcon from '../img/video-icon.svg';

const ControlsContainer = styled.div`
	position: relative;
	z-index: 1;
	grid-area: c;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10rem 1rem 10rem;
`;

const StreamControl = styled.button`
	height: 5rem;
	min-width: 5rem;
	margin: 0 .5rem;
	outline: none;
	border-radius: 100%;
	background-image: url(${props => props.icon});
	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 2.25rem;
	background-color: white;
	opacity: .75;
	transition: opacity .25s ease-in-out;
	:hover {
		opacity: 1;
	}
`;

const SupplyStreamControls = ({ streamStatus, camID, index, numImages, suppliesActions, startStream }) => {
	return (
		<ControlsContainer>
			{streamStatus === "live" ? (
				<StreamControl onClick={() => suppliesActions.getPrevSnapshot(camID, index, numImages)}>
					close
				</StreamControl>
			) : (
				<Fragment>
					<StreamControl
						onClick={() => suppliesActions.getPrevSnapshot(camID, index, numImages)}
						icon={prevIcon} />
					<StreamControl 
						onClick={() => suppliesActions.getNextSnapshot(camID, index, numImages)}
						icon={nextIcon} />
					<StreamControl
						onClick={() => suppliesActions.getNewSnapshot()}
						icon={cameraIcon} />
					<StreamControl
						onClick={() => startStream()}
						icon={videoIcon} />
				</Fragment>
			)}
		</ControlsContainer>
	);
}

export default SupplyStreamControls;
