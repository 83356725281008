import React from 'react';
import styled from 'styled-components';

const ValueContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SuppliesValue = ({ children }) => {
	return (
		<ValueContainer>
			{children}
		</ValueContainer>	
	);
}

export default SuppliesValue;
