import React from 'react';
import { connect } from 'react-redux';


const Alert = ({ alerts }) => {
  return (alerts !== null &&
  alerts.size > 0 &&
  alerts.map(alert => (
    <div key={alert.id} className={`alert alert-${alert.alertType}`}>
      {alert.message}
    </div>
  )));
}
const mapStateToProps = state => ({
  alerts: state.get('alerts')
});



export default connect(mapStateToProps)(Alert);