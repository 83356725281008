import axios from 'axios';
import * as actions from '../action-creators';
import uuid from 'uuid';

export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      'Content-Type': 'application/json'
    };

    const res = await axios.get('https://dev.taskunite.com/api/auth', config);
    dispatch(actions.userLoaded(res.data.user));
  } catch (err) {
    dispatch(actions.authError());
  }
}

export const register = ({ name, email, password, facility }) => async dispatch => { 
  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  };

  const body = JSON.stringify({ name, email, password, facility });
  try {
    const res = await axios.post('https://dev.taskunite.com/api/users', body, config);
    
    if (res.status === 200) {
      dispatch(actions.registerSuccess(res.data.token));
      dispatch(loadUser());
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => {
        let id = uuid.v4()
        dispatch(actions.setAlert(error.msg, 'danger', id))
        setTimeout(() => dispatch(actions.removeAlert(id)),  5000);
      });
    } else if (err.message) {
      let id = uuid.v4()
      dispatch(actions.setAlert("Register failed; please try again later", 'danger', id));
      setTimeout(() => dispatch(actions.removeAlert(id)),  5000);
    }
    dispatch(actions.registerFail());
  }
}

export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post('https://dev.taskunite.com/api/auth', body, config);
    if (res.status === 200) {
      dispatch(actions.loginSuccess(res.data.token));
      dispatch(loadUser());
    }
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => {
        let id = uuid.v4()
        dispatch(actions.setAlert(error.msg, 'danger', id))
        setTimeout(() => dispatch(actions.removeAlert(id)), 5000);
      });
    } else if (err.message) {
      let id = uuid.v4()
      dispatch(actions.setAlert("Login failed; please try again later", 'danger', id));
      setTimeout(() => dispatch(actions.removeAlert(id)), 5000);
    }

    dispatch(actions.loginFail());
  }
}

export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export const logout = () => dispatch => {
  dispatch(actions.logout());
};
