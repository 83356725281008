import React, { Fragment, useState } from 'react';

const ToggleContent = ({ toggleComp, contentComp }) => {
	const [isVisible, setIsVisible] = useState(false);
	const handleOpen = () => setIsVisible(true);
	const handleClose = e => {
		const elem = e.target;
		if (elem.id === 'closer' || !elem.closest('#toggleContainer')) { 
			setIsVisible(false);
		}
	};

	return (
		<Fragment>
			{toggleComp(handleOpen)}
			{isVisible && contentComp(handleClose)}
		</Fragment>
	);
}

export default ToggleContent;
