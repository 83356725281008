import React from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
	height: 80%;
`;

const PreviewImage = styled.img.attrs(props => ({
	src: props.src,
	alt: props.alt
}))`
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 8px 8px 0 0;
`;

const CameraPreviewImage = ({ src, alt }) => {
	return (
		<ImageContainer>
			<PreviewImage src={src} alt={alt} />
		</ImageContainer>
	);
}

export default CameraPreviewImage;
