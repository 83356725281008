import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import getIcon from '../hooks/icons';

const ResultContainer = styled.div`
	position: relative;
	z-index: inherit;
	display: flex;
	align-items: center;
	padding: .75rem .5rem .75rem 4.5rem;
	width: 100%;
	background-color: white;
	background-image: url(${props => getIcon(props.type)});
	background-repeat: no-repeat;
	background-position: 1rem center;
	&:hover {
		background-color: #D4EAF0;
	}
`;

const SearchResult = ({ name, type, linkURL, handleClose}) => {
	return (
		<ResultContainer type={type}>
			{linkURL.length < 1 && 
				<h1>{name}</h1>}
			{linkURL.length >= 1 && 
				<Link to={linkURL} onClick={handleClose}>
					<h1>{name}</h1>
				</Link>}
		</ResultContainer>
	);
}

export default SearchResult;
