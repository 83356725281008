import React from 'react';
import styled from 'styled-components';

const BarContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	border-radius: 0 0 8px 8px;
	padding: 0 1rem;
	height: 20%;
`;

const BarTitle = styled.h1`
	text-align: left;
	font-size: 1.5rem;
`;

const BarTime = styled.h1`
	text-align: right;
	font-size: 1.5rem;
`;

const CameraPreviewBar = ({ name, time }) => {
	return (
		<BarContainer>
			<BarTitle>{name}</BarTitle>
			<BarTime>{time}</BarTime>
		</BarContainer>
	);
}

export default CameraPreviewBar;
