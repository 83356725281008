import { fromJS } from 'immutable';
import * as Core from './core';

const INITIAL_STATE = fromJS({
  auth: {
    token: localStorage.getItem('token'),
    isAuthenticated: true,
    loading: true,
    user: null
  },
  alerts: [],
  title: "Title",
  searchResults: {
    tasks: [],
    supplies: []
  },
  supplySnapshots: {
    currentIndex: 0,
    name: '',
    cameraID: "",
    streamStatus: "pending",
    images: []
  },
  suppliesPreviews: [
    {
      cameraID: '',
      name: '',
      url: '',
      timestamp: ''
    }
  ],
  cameraLiveStream: {

  }
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
      case 'SET_TITLE':
        return Core.setTitle(state, action.title);
      case 'ADD_TASK':
        return state.updateIn(['tasks'],
                      tasksState => Core.addTask(tasksState, action.task));
      case 'LOGIN_SUCCESS':
      case 'REGISTER_SUCCESS':
        localStorage.setItem('token', action.token);
        return state.updateIn(['auth'], authState => Core.registerSuccess(authState, action.token));
      case 'USER_LOADED':
        return state.updateIn(['auth'], authState => Core.userLoaded(authState, action.payload));
      case 'LOGOUT':
      case 'REGISTER_FAIL':
      case 'LOGIN_FAIL':
      case 'AUTH_ERROR':
        localStorage.removeItem('token');
        let removedTokenState = state.updateIn(['auth'], authState => authState.set('token', null));
        let unauthenticatedState = removedTokenState.updateIn(['auth'], authState => authState.set('isAuthenticated', false));
        return unauthenticatedState;
      case 'SET_ALERT':
        return state.updateIn(['alerts'], alertsState => alertsState.push(action.payload));
      case 'REMOVE_ALERT':
        return state.updateIn(['alerts'], alertsState => alertsState.filter(alert => alert.uuid !== action.payload));
      case 'UPDATE_SEARCH_RESULTS':
        return state.set('searchResults', fromJS(action.results));
      case 'CLEAR_SNAPSHOTS':
        return state.set('supplySnapshots', {currentIndex: 0, cameraID: "", images: []});
      case 'LOAD_SUPPLY_SNAPSHOTS':
        let nextState = state.setIn(['supplySnapshots', 'cameraID'], action.cameraID);
        nextState = state.setIn(['supplySnapshots', 'name'], action.name);
        return nextState.setIn(['supplySnapshots', 'images'], fromJS(action.images));
      case 'RESET_SUPPLY_INDEX':
        return state.setIn(['supplySnapshots', 'currentIndex'], 0);
      case 'REQUEST_STREAM':
        return state.setIn(['supplySnapshots', 'streamStatus'], 'requesting');
      case 'CONNECT_STREAM':
        return state.setIn(['supplySnapshots', 'streamStatus'], 'connecting');
      case 'START_STREAM':
        return state.setIn(['supplySnapshots', 'streamStatus'], 'live');
      case 'PAUSE_STREAM':
        return state.setIn(['supplySnapshots', 'streamStatus'], 'idle');
      case 'LOAD_SUPPLIES_PREVIEWS':
        return state.set('suppliesPreviews', fromJS(action.previews));        
      case 'ADD_TO_SUPPLY_INDEX':
        let newIndex = Math.max(state.get('supplySnapshots').get('currentIndex') + action.value, 0);
        return state.setIn(['supplySnapshots', 'currentIndex'], newIndex);
      case 'SET_LIVESTREAM_DATA':
        return state.set('cameraLiveStream', action.data);
      default:
        return state;
  }
}
