import React, { Component } from 'react';
import styled from 'styled-components';

const YContainer = styled.div`
	grid-area: y;
	display: flex;
	height: 30rem;
	flex-direction: column;
	justify-content: space-between;
	padding-right: .5rem;
	text-align: right;
`;

class YAxis extends Component {
  render() {
    return (
    	<YContainer className={this.props.className}>
    		<h1>12</h1>
            <h1>10</h1>
    		<h1>8</h1>
    		<h1>6</h1>
    		<h1>4</h1>
    		<h1>2</h1>
    		<h1>0</h1>
    	</YContainer> 
    );
  }
}

export default YAxis;
